import api from '@/services/api/api-search';

export default class SearchService {
  static async search(val) {
    try {
      let res = await api.search({ search: val });
      return res.results;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}