<script>
import SearchService from '@/services/searchService'

export default {
  render() {
    return this.$scopedSlots.default({
      updateFocused: this.updateFocused,
      isFocused: this.isFocused,
      search: this.search,
      list: this.list,
      loading: this.loading
    })
  },

  data: () => ({
    isFocused: false,
    loading: false,
    list: []
  }),

  methods: {
    updateFocused(val) {
      this.isFocused = val
    },

    async search(val) {
      try {
        this.loading = true
        let res = await SearchService.search(val)
        this.list = res
        return res
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
