import CRMauth from "@/services/CRMinitAuth"

const search = query =>
  CRMauth.get(`${process.env.VUE_APP_CRM_API}candidates_search/`, {
    params: query
  });

// const getCoursesBy = query => coursesByLocation

export default {
  search
}